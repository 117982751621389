import React from 'react';
import { CSSTransition } from 'react-transition-group';
import aboutImage from '../assets/2-layers.jpg';

const About = ({ in: inProp }) => {
  return (
    <div className="container mx-auto px-4 py-16 md:px-8">
      <CSSTransition in={inProp} timeout={500} classNames="fade" appear>
        <div>
          <div className="flex flex-col md:flex-row items-center justify-center">
            {/* Image on the left with spacing */}
            <div className="md:w-1/2 md:pr-8 mb-4 md:mb-0">
              <img src={aboutImage} alt="About Us" className="rounded-lg shadow-lg mx-auto" />
            </div>
            {/* Text content on the right */}
            <div className="md:w-1/2">
              <h2 className="text-3xl sm:text-4xl md:text-5xl mb-4 font-thin text-nice-brown font-butler text-center md:text-left">About Us</h2>
              <p className="text-base sm:text-lg md:text-xl text-gray-600 leading-relaxed">
                At S S Tailor London, we pride ourselves on being the go-to professional tailor in Canary Wharf for all your alteration needs. With years of experience in the industry, we have perfected the art of tailoring to ensure that every garment fits you like a glove. Whether you need alterations for suits, dress, bridal dresses or any other type of clothing, our skilled team is here to provide you with exceptional service. We understand that finding a reliable and professional company for your alteration needs can be challenging, which is why we strive to exceed your expectations in every way. Our attention to detail and commitment to quality craftsmanship sets us apart from the competition. When you choose S S Tailor London, you can trust that your garments are in good hands. Experience the difference of our expert alterations in Canary Wharf and discover how we can transform your clothing into a perfect fit.
              </p>
            </div>
          </div>
        </div>
      </CSSTransition>
      <div className="border-b border-deep-brown border-solid mt-8"></div>
    </div>
  );
}

export default About;
