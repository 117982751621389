import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const BookingPage = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData object
    const formData = new FormData();
    formData.append('fullName', fullName);
    formData.append('phoneNumber', phoneNumber);
    formData.append('email', email);
    formData.append('message', message);

    try {
      // Submit form data to Formspree endpoint
      const response = await fetch('https://formspree.io/f/mvgpzowa', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json',
        },
      });

      // Handle response
      if (response.ok) {
        setSuccess('Booking request sent successfully!');
        setFullName('');
        setPhoneNumber('');
        setEmail('');
        setMessage('');
      } else {
        setError('Failed to send booking request.');
      }
    } catch (err) {
      setError('Error occurred while sending booking request.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-brown-700 p-6">
      <div className="max-w-lg w-full bg-white shadow-lg rounded-lg p-6 border border-gray-300">
        <Link to="/" className="block mb-4 text-blue-500 hover:underline">
          &larr; Go Back to Home
        </Link>

        <h2 className="text-2xl font-semibold mb-4">Book Your Appointment</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="fullName" className="block text-gray-700">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="phoneNumber" className="block text-gray-700">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-700">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-nice-brown text-white py-2 rounded-lg shadow-lg hover:bg-dark-nice-brown transition-colors"
          >
            Submit
          </button>
        </form>

        {success && <p className="mt-4 text-green-600">{success}</p>}
        {error && <p className="mt-4 text-red-600">{error}</p>}
      </div>
    </div>
  );
};

export default BookingPage;
