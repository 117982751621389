import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Gallery from '../components/Gallery';
import ScrollingText from '../components/ScrollingText';
import About from '../components/About';
import GoogleReviews from '../components/GoogleReviews';
import Contact from '../components/Contact'; // Import Contact component
import { CSSTransition } from 'react-transition-group';

const Home = () => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  return (
    <div>
        <Header />
          <div className="overflow-x-hidden">  {/* Prevent horizontal scrolling */}
            <div id="home" className="w-full">
              <Hero />
            </div>
            <ScrollingText />
            <div id="about" className="border-t">
              <About in={inProp} />
            </div>
            <div id="services" className="w-full">
              <CSSTransition in={inProp} timeout={500} classNames="fade" appear>
                <div>
                  <Services />
                </div>
              </CSSTransition>
            </div>
            <div id="reviews" className="w-full">
              <GoogleReviews />
            </div>
            <div id="gallery" className="w-full">
              <CSSTransition in={inProp} timeout={500} classNames="fade" appear>
                <div>
                  <Gallery />
                </div>
              </CSSTransition>
            </div>
            <div id="contact" className="border-t">
              <Contact />
            </div>
            <Footer />
          </div>
    </div>
  );
};

export default Home;
