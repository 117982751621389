function importAll(r) {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }
  
  // Import images from each category folder
  const bridalImages = importAll(require.context('../assets/bridal', false, /\.(jpg|jpeg|png)$/));
  const suitImages = importAll(require.context('../assets/suit', false, /\.(jpg|jpeg|png)$/));
  const repairImages = importAll(require.context('../assets/repair', false, /\.(jpg|jpeg|png)$/));
  const casualImages = importAll(require.context('../assets/casual', false, /\.(jpg|jpeg|png)$/));
  const bespokeGarmentImages = importAll(require.context('../assets/bespokeGarment', false, /\.(jpg|jpeg|png)$/));
  
  export { bridalImages, suitImages, repairImages, casualImages, bespokeGarmentImages };
  