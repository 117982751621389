import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import Slider from 'react-slick'; // Import Slider component from react-slick

// Function to dynamically load all images from the 'suit' folder
const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
};

// Import images from the 'suit' folder
const images = importAll(require.context('../assets/suit', false, /\.(jpg|jpeg|png)$/));

// Extract image paths
const galleryImages = Object.values(images);

const limitedGalleryImages = galleryImages.slice(0, 5);

const settings = {
  dots: true, // Show navigation dots
  infinite: true, // Loop slides
  speed: 500, // Transition speed
  slidesToShow: 2, // Number of slides to show
  slidesToScroll: 1, // Number of slides to scroll
  autoplay: true, // Enable automatic sliding
  autoplaySpeed: 3000, // Delay between slides in milliseconds
};

const Gallery = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-thin text-deep-brown font-butler mb-8">Gallery</h2>
        <div className="w-full max-w-3xl mx-auto">
          <Slider {...settings}>
            {limitedGalleryImages.map((image, index) => (
              <div key={index} className="p-4">
                <img
                  src={image}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-64 object-cover rounded-md"
                />
              </div>
            ))}
          </Slider>
        </div>
        <Link to="/gallery" className="mt-8 inline-block bg-deep-brown text-white py-2 px-4 ">View More</Link>
        <div className="border-b border-deep-brown border-solid mt-8"></div>
      </div>
    </section>
  );
}

export default Gallery;
