import React from 'react';
import './ScrollingText.css';

const ScrollingText = () => {
  return (
    <div className="bg-deep-brown py-4 overflow-hidden scrolling-container">
      <div className="scrolling-text">
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Perfect Fit Guaranteed</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Premium Quality Fabrics</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Book Your Appointment Today!</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Perfect Fit Guaranteed</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Premium Quality Fabrics</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Book Your Appointment Today!</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Perfect Fit Guaranteed</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Premium Quality Fabrics</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Book Your Appointment Today!</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Perfect Fit Guaranteed</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Premium Quality Fabrics</span>
        <span className="mx-16 text-base sm:text-lg md:text-xl text-white">Book Your Appointment Today!</span>
      </div>
    </div>
  );
}

export default ScrollingText;
