import React, { useEffect, useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const header = document.querySelector('header');
      const nav = document.querySelector('nav');
      if (nav.scrollWidth > header.clientWidth) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 100, // Adjust offset here
            behavior: 'smooth',
          });
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Handle initial load

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-brown sticky-header p-4 w-full z-10 text-brown relative">
      <nav className="container mx-auto flex items-center justify-between lg:justify-center">
        <div className="text-white font-bold text-lg">
          S S Tailor London
        </div>
        <div className="flex-grow lg:flex lg:justify-center hidden lg:block">
          <ul className="lg:flex space-x-12 items-center">
            <li>
              <a href="#home" className="text-gray-500 hover:text-white">Home</a>
            </li>
            <li className="mx-6 hidden lg:block">
              <span className="text-gray-300">|</span>
            </li>
            <li>
              <a href="#about" className="text-gray-500 hover:text-white">About</a>
            </li>
            <li className="mx-6 hidden lg:block">
              <span className="text-gray-300">|</span>
            </li>
            <li>
              <a href="#services" className="text-gray-500 hover:text-white">Services</a>
            </li>
            <li className="mx-6 hidden lg:block">
              <span className="text-gray-300">|</span>
            </li>
            <li>
              <a href="#gallery" className="text-gray-500 hover:text-white">Gallery</a>
            </li>
            <li className="mx-6 hidden lg:block">
              <span className="text-gray-300">|</span>
            </li>
            <li>
              <a href="#contact" className="text-gray-500 hover:text-white">Contact</a>
            </li>
          </ul>
        </div>
        <div className="block lg:hidden">
          <button onClick={toggleMenu} className="text-gray-500 hover:text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </nav>
      {isMenuOpen && (
        <div className="absolute top-full left-0 w-full bg-black rounded-md shadow-lg z-20 lg:hidden">
          <ul className="py-1">
            <li>
              <a href="#home" className="block px-4 py-2 text-gray-500 hover:bg-gray-100">Home</a>
            </li>
            <li>
              <a href="#about" className="block px-4 py-2 text-gray-500 hover:bg-gray-100">About</a>
            </li>
            <li>
              <a href="#services" className="block px-4 py-2 text-gray-500 hover:bg-gray-100">Services</a>
            </li>
            <li>
              <a href="#gallery" className="block px-4 py-2 text-gray-500 hover:bg-gray-100">Gallery</a>
            </li>
            <li>
              <a href="#contact" className="block px-4 py-2 text-gray-500 hover:bg-gray-100">Contact</a>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default Header;
