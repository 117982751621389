import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { bridalImages, suitImages, repairImages, casualImages, bespokeGarmentImages } from '../helpers/ImageLoader'; // Adjust path if needed

const tabData = {
  bridal: Object.values(bridalImages),
  suit: Object.values(suitImages),
  repair: Object.values(repairImages),
  casual: Object.values(casualImages),
  bespokeGarment: Object.values(bespokeGarmentImages),
};

const TabbedGallery = () => {
  const [activeTab, setActiveTab] = useState('bridal');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (window.innerWidth < 768) {
      setIsMenuOpen(false); // Close menu on mobile view after selecting a tab
    }
  };

  const handleImageClick = (index) => {
    setSelectedImage(tabData[activeTab][index]);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const showPreviousImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : tabData[activeTab].length - 1));
    setSelectedImage(tabData[activeTab][(currentIndex > 0 ? currentIndex - 1 : tabData[activeTab].length - 1)]);
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex < tabData[activeTab].length - 1 ? prevIndex + 1 : 0));
    setSelectedImage(tabData[activeTab][(currentIndex < tabData[activeTab].length - 1 ? currentIndex + 1 : 0)]);
  };

  const handlers = useSwipeable({
    onSwipedLeft: showNextImage,
    onSwipedRight: showPreviousImage,
  });

  useEffect(() => {
    if (selectedImage) {
      // Adding the bounce effect
      const imageElement = document.querySelector('.modal-image');
      imageElement.classList.add('bounce');
      const timeoutId = setTimeout(() => {
        imageElement.classList.remove('bounce');
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [selectedImage]);

  return (
    <div className="container mx-auto text-center py-12">
      <h2 className="text-3xl font-thin text-deep-brown font-butler mb-8">Gallery</h2>
      
      {/* Mobile Menu Button */}
      <div className="block lg:hidden text-center mb-4">
        <button
          className="text-gray-500 hover:text-white focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="lg:hidden mb-4">
          <div className="bg-white rounded-md shadow-lg">
            <button
              className={`tab w-full text-left px-4 py-2 ${activeTab === 'bridal' ? 'active' : ''}`}
              onClick={() => handleTabClick('bridal')}
            >
              Bridals
            </button>
            <button
              className={`tab w-full text-left px-4 py-2 ${activeTab === 'suit' ? 'active' : ''}`}
              onClick={() => handleTabClick('suit')}
            >
              Suits
            </button>
            <button
              className={`tab w-full text-left px-4 py-2 ${activeTab === 'repair' ? 'active' : ''}`}
              onClick={() => handleTabClick('repair')}
            >
              Repairs
            </button>
            <button
              className={`tab w-full text-left px-4 py-2 ${activeTab === 'casual' ? 'active' : ''}`}
              onClick={() => handleTabClick('casual')}
            >
              Casual
            </button>
            <button
              className={`tab w-full text-left px-4 py-2 ${activeTab === 'bespokeGarment' ? 'active' : ''}`}
              onClick={() => handleTabClick('bespokeGarment')}
            >
              Bespoke Garments
            </button>
          </div>
        </div>
      )}
      
      {/* Tabs for larger screens */}
      <div className="hidden lg:flex justify-center mb-8">
        <button
          className={`tab ${activeTab === 'bridal' ? 'active' : ''}`}
          onClick={() => handleTabClick('bridal')}
        >
          Bridals
        </button>
        <button
          className={`tab ${activeTab === 'suit' ? 'active' : ''}`}
          onClick={() => handleTabClick('suit')}
        >
          Suits
        </button>
        <button
          className={`tab ${activeTab === 'repair' ? 'active' : ''}`}
          onClick={() => handleTabClick('repair')}
        >
          Repairs
        </button>
        <button
          className={`tab ${activeTab === 'casual' ? 'active' : ''}`}
          onClick={() => handleTabClick('casual')}
        >
          Casual
        </button>
        <button
          className={`tab ${activeTab === 'bespokeGarment' ? 'active' : ''}`}
          onClick={() => handleTabClick('bespokeGarment')}
        >
          Bespoke Garments
        </button>
      </div>
      
      <div className="flex flex-col items-center space-y-8">
        {tabData[activeTab].map((image, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-md cursor-pointer" onClick={() => handleImageClick(index)}>
            <img
              src={image}
              alt={`Gallery Image ${index + 1}`}
              className="w-full h-auto max-w-md object-contain"
            />
          </div>
        ))}
      </div>

      {/* Modal for displaying full-size images */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" {...handlers}>
          <button className="absolute top-4 right-8 text-white color-white text-4xl" onClick={closeModal}>&times;</button>
          <button className="absolute left-20 text-white color-white text-4xl hidden lg:block" onClick={showPreviousImage}>←</button>
          <button className="absolute right-20 text-white color-white text-4xl hidden lg:block" onClick={showNextImage}>→</button>
          <img
            src={selectedImage}
            alt="Full Size"
            className="max-w-full max-h-full object-contain modal-image"
            onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking on the image
          />
        </div>
      )}

      <style jsx>{`
        .tabs {
          display: flex;
          justify-content: center;
          gap: 1rem;
        }
        .tab {
          background: #f1f1f1;
          border: none;
          padding: 0.5rem 1rem;
          cursor: pointer;
          border-radius: 0.25rem;
          transition: background 0.3s ease;
        }
        .tab.active {
          background: #4a4a4a;
          color: white;
        }
        .tab:hover {
          background: #ddd;
        }
        .modal-image.bounce {
          animation: bounce-right 1s ease;
        }
        @keyframes bounce-right {
          0%, 100% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(-10px);
          }
        }
      `}</style>
    </div>
  );
};

export default TabbedGallery;
