import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GoogleReviews = () => {
  const reviews = [
    {
      name: "Ninette Mugisha",
      review: "I had a 2 piece set tailored (top and skirt) and I was really happy with the result. It was quite urgent as I had to travel and was super happy with the customer service offered. This was also my first time using SS tailor and I will definitely be coming back. If you looking for someone that has attention to detail, efficiency and best result… this is it! Thank you once again!!",
    },
    {
      name: "Roman Baca",
      review: "Sonia tailored a pair of suit trousers and a patterned jacket for me. The results were perfect! Sonia was attentive, helpful, and honest. The price was affordable. The pieces were delivered on or before when promised. I highly recommend SS Tailor.",
    },
    {
      name: "Giancarlo Beato",
      review: "Sonia was very professional and kind, the work speaks for itself. The dress was longer than needed, and she cut and even added a loop to the tail for easy holding. Amazing work!",
    },
    {
      name: "Zoe B",
      review: "Sonia did a fantastic job shortening my dress in 24hrs before a wedding. The fabric is a stretchy velvet and not easy to work with. She advised me on the appropriate length to make it easy to walk in given the shape of the dress. It worked out perfectly!",
    },
    {
      name: "Fabrizio Ferracin",
      review: "Excellent tailor! Sonia is a friendly, capable and experienced tailor. I had my trousers adjusted (length and waist) and now they fit perfectly. If you are looking for a tailor, I recommend you try SS Tailor!",
    },
    {
      name: "Stanley Collins",
      review: "Excellent service provided on two suit trousers one which I am using for an up coming wedding. Prompt reliable service, will defiantly be using again and would recommend to friends and family. Thank your",
    },
    {
      name: "Penny Freeborn",
      review: "Sonia was very lovely to deal with. She was honest about her tailoring plan and worked the material the best she could. I would deal with S S Tailor again. My dress needed to be hemmed and the material for this was used to make a higher neckline. Good communication and care of the garment. Would use again when short notice.",
    },
    {
      name: "Olimjon Suleymanov",
      review: "Sonia tailored my two trousers next day despite of her extremely busy schedule. High quality of work, reasonable price, highly recommended 👍🏻",
    },
    // Add more reviews as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Adjust autoplay speed if needed
  };

  return (
    <div className="bg-gray-100 py-8">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-thin text-deep-brown mb-2 font-butler">Google Reviews</h2>
          <p className="text-lg text-gray-700">See what our customers have to say about us!</p>
        </div>
        <div className="w-full max-w-4xl mx-auto">
          <Slider {...settings}>
            {reviews.map((review, index) => (
              <div key={index} className="p-4 flex flex-col items-center text-center">
                <p className="text-xl font-bold text-gray-900 mb-2">{review.name}</p>
                <p className="text-lg text-gray-700">{review.review}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="border-b border-deep-brown border-solid mt-8"></div>
    </div>
  );
};

export default GoogleReviews;
