import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import TabbedGallery from '../components/TabbedGallery'; // Ensure correct path

const GalleryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <header className="bg-deep-brown text-white py-4 text-center">
        <h1 className="text-4xl font-semibold">Gallery</h1>
        <Link to="/" className="underline text-white mt-2 block">Back to Home</Link>
      </header>
      <TabbedGallery />
      <footer className="bg-deep-brown text-white py-4 text-center">
        <Link to="/" className="underline">Back to Home</Link>
      </footer>
    </div>
  );
};

export default GalleryPage;
