import React from 'react';
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Contact = () => {
  return (
    <div className="container mx-auto py-16 flex flex-col md:flex-row items-center justify-center">
      {/* Contact Information */}
      <div className="md:w-1/2 md:pr-8 mb-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-thin text-deep-brown font-butler text-center mb-8">Contact Us</h2>
        <div className="text-center">
        <p className="text-base sm:text-lg md:text-xl text-gray-900 mb-4">
          Phone:&nbsp;
          <a href="tel:07440114476" className="text-blue-500 hover:underline">
            07440 114476
          </a>
        </p>
          <p className="text-base sm:text-lg md:text-xl text-gray-900 mb-4">
            Email:&nbsp;
            <a href="mailto:sstailorlondon@gmail.com" className="text-blue-500 hover:underline">
               sstailorlondon@gmail.com
            </a>
          </p>
          <p className="text-base sm:text-lg md:text-xl text-gray-900">Address: 61, Salford House, Seyssel St, London E14 3HZ</p>
          <Link to="/booking" className="mt-8 inline-block px-6 py-3 bg-deep-brown text-white font-semibold shadow-lg hover:bg-dark-nice-brown transition-colors">
            Book Now
          </Link>
        </div>
      </div>

      {/* Map Container */}
      <div className="md:w-1/2 h-96 mb-8">
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2484.190372808752!2d-0.0056232!3d51.4913739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876030a791865a1%3A0x8953a7239a6442e6!2sS%20S%20Tailor%20London!5e0!3m2!1sen!2suk!4v1721069006999!5m2!1sen!2suk"
          width="100%"
          height="100%"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  );
};

export default Contact;
