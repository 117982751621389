import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 S S Tailor London. All rights reserved.</p>
        <p>Follow us on <a href="https://www.instagram.com/sstailorlondon/" className="text-blue-500">Instagram</a>, <a href="https://www.facebook.com/people/S-S-Tailor-London/100077915077140/" className="text-blue-500">Facebook</a>, and <a href="https://www.linkedin.com/in/sonia-saleha-akter-83b9351ab/?originalSubdomain=uk" className="text-blue-500">LinkedIn</a>.</p>
      </div>
    </footer>
  );
}

export default Footer;
