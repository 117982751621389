import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import GalleryPage from './pages/GalleryPage'; // Import your GalleryPage component
import BookingPage from './pages/BookingPage';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/booking" element={<BookingPage />} />
      {/* Add other routes as needed */}
    </Routes>
  </Router>
);

export default App;
